// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("C:\\Source\\JAMM\\redapple\\www\\src\\pages\\404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-form-submitted-jsx": () => import("C:\\Source\\JAMM\\redapple\\www\\src\\pages\\contact-form-submitted.jsx" /* webpackChunkName: "component---src-pages-contact-form-submitted-jsx" */),
  "component---src-pages-contact-form-jsx": () => import("C:\\Source\\JAMM\\redapple\\www\\src\\pages\\contact-form.jsx" /* webpackChunkName: "component---src-pages-contact-form-jsx" */),
  "component---src-pages-explore-jsx": () => import("C:\\Source\\JAMM\\redapple\\www\\src\\pages\\explore.jsx" /* webpackChunkName: "component---src-pages-explore-jsx" */),
  "component---src-pages-index-jsx": () => import("C:\\Source\\JAMM\\redapple\\www\\src\\pages\\index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("C:\\Source\\JAMM\\redapple\\www\\src\\pages\\privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "C:\\Source\\JAMM\\redapple\\www\\.cache\\data.json")

