/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

//import "core-js/es6/map";
//import "core-js/es6/set";

//import "core-js";
//import "raf/polyfill";

//import "core-js/modules/es6.set";

//import "babel-polyfill";

//doesn't work in dev, may in prod
import "core-js/es6/map";
import "core-js/es6/set";
